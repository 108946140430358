import { LineItem } from '@Types/cart/LineItem';
import { Product } from '@Types/product/Product';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
interface PageViewData {
  type: string;
  condition: boolean;
  name: string;
  searchIndex?: string;
}

export const getPageDataLayerObject = (dataSources: any, locale: string): PageViewData => {
  const pathname = window.location.pathname.split(`/${locale.toUpperCase() === 'US' ? '' : locale}`)[1];

  const pageViewDataLayerArray: PageViewData[] = [
    { type: 'home', condition: pathname === '', name: 'Home' },
    { type: 'checkout', condition: pathname === 'checkout', name: 'Checkout' },
    { type: 'cart', condition: pathname === 'cart', name: 'Cart' },
    {
      type: 'search',
      condition: pathname === 'search',
      name: 'Search',
      searchIndex: dataSources?.__master?.indexName,
    },
    { type: 'confirmation', condition: pathname === 'confirmation', name: 'Confirmation' },
  ];
  const product: Product | undefined = dataSources?.[Object.keys(dataSources)[0]]?.product;
  const contentName = dataSources?.__master?.content?._meta?.name;
  const pageViewData = product
    ? { type: 'product', condition: true, name: product.name }
    : {
        type: 'content',
        condition: true,
        name: contentName,
        searchIndex: dataSources?.__master?.content?.productsData?.indexName,
      };

  return pageViewDataLayerArray.find((element) => element.condition) || pageViewData;
};

export const mapLineItemForDataLayer = (lineItem: LineItem, index?: number) => {
  return {
    index: index,
    affiliation: 'Thomas Pink',
    item_id: lineItem.variant.sku,
    item_product_id: lineItem.productId,
    item_parent_sku: lineItem.variant.attributes['TP_ARTICLE_CODE'],
    item_name: lineItem.name,
    item_brand: 'Thomas Pink',
    item_category: lineItem.variant.attributes['TP_PRODUCT_TYPE'],
    price: CurrencyHelpers.formatMoneyFloatValue(lineItem.totalPrice),
    item_original_price: CurrencyHelpers.formatMoneyFloatValue(lineItem.price),
    discount: CurrencyHelpers.formatMoneyFloatValue(
      CurrencyHelpers.subtractCurrency(
        CurrencyHelpers.multiplyCurrency(lineItem.price, lineItem.count),
        lineItem.totalPrice,
      ),
    ),
    item_on_sale: lineItem.discountedPrice !== undefined,
    quantity: lineItem.count,
    item_fit: lineItem.variant.attributes['TP_PRODUCT_FIT'],
    item_colour: lineItem.variant.attributes['TP_COLOUR'],
    item_cuff: lineItem.variant.attributes['TP_CUFF'],
    item_length: lineItem.variant.attributes['TP_SLEEVE_LENGTH'],
    item_size: lineItem.variant.attributes['TP_SIZE'],
    item_search_id: lineItem.variant.attributes['TP_PRODUCT_ID'],
  };
};
