import { LineItem } from '@Types/cart/LineItem';
import { Money } from '@Types/product/Money';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

export function calculateTotalDiscount(lineItems: LineItem[], currencyCode: string) {
  return CurrencyHelpers.formatMoneyFloatValue(
    lineItems.reduce(
      (sum: Money, lineItem: LineItem) => {
        const totalDiscount = CurrencyHelpers.multiplyCurrency(
          CurrencyHelpers.subtractCurrency(lineItem.price, lineItem.totalPrice),
          lineItem.count,
        );
        return CurrencyHelpers.addCurrency(sum, totalDiscount);
      },
      {
        fractionDigits: 2,
        centAmount: 0,
        currencyCode,
      },
    ),
  );
}
