import { Discount } from '@Types/cart/Discount';
import { isDevEnv } from 'helpers/utils/environment';
import { Locale } from 'frontastic/provider/locale/locales';

export const getTransactionDataLayerData = (
  orderResponse: any,
  payment: string,
  shippingMethodName: string,
  discountCodes: Discount[],
) => {
  const dataLayerData = JSON.parse(JSON.stringify(orderResponse));
  dataLayerData.payments = payment;
  dataLayerData.shippingMethodName = shippingMethodName;
  dataLayerData.discountCodes = discountCodes.map((discountCodes) => discountCodes.code);
  return dataLayerData;
};

export const getAlgoliaIndex = (locale: Locale) => {
  return `tp_product_${isDevEnv() ? 'staging' : 'prod'}_gb_${locale.languageCode}`;
};
