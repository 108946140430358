import { Account } from '@Types/account/Account';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import { AlgoliaProduct, Product } from '@Types/product/Product';
import cookieCutter from 'cookie-cutter';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { getAlgoliaIndex } from 'helpers/dataLayerHelpers/dataLayerHelpers';
import { calculateTotalDiscount } from 'helpers/utils/cartUtils/cartUtils';
import { getPageDataLayerObject, mapLineItemForDataLayer } from 'helpers/utils/dataLayerUtils/dataLayerUtils';
import { v4 as uuidv4 } from 'uuid';
import { Locale } from 'frontastic/provider/locale/locales';
import { ProductVariantOption } from '../../../types/product/ProductVariantOption';
class TagManager {
  private static instance: TagManager | null = null;
  private dataLayerIdentifier: string;
  private userId: string;

  constructor(dataLayerIdentifier = 'dataLayer') {
    if (!TagManager.instance) {
      TagManager.instance = this;
      this.dataLayerIdentifier = dataLayerIdentifier;
    }

    return TagManager.instance;
  }

  setUserId = () => {
    if (!cookieCutter.get('tpUserID')) {
      cookieCutter.set('tpUserID', uuidv4(), { path: '/' });
      this.userId = cookieCutter.get('tpUserID');
    }
    this.userId = cookieCutter.get('tpUserID');
  };

  pageView(dataSources: any, currentLocale: Locale) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    const pageDataLayerObject = getPageDataLayerObject(dataSources, currentLocale.localeCode);
    if (pageDataLayerObject?.condition && pageDataLayerObject.type !== 'confirmation') {
      this.resetDataLayer();
    }

    dataLayer.push({
      event: 'page_view',
      timestamp: Date.now(),
      page: {
        type: pageDataLayerObject.type,
        name: pageDataLayerObject.name,
        currency: currentLocale.currency,
        market: currentLocale.localeCode.toUpperCase(),
        searchIndex: pageDataLayerObject.searchIndex ?? getAlgoliaIndex(currentLocale),
      },
      user: {
        userId: this.userId || 'user-undefined',
        logged_in: false,
      },
      _clear: true,
    });
  }

  searchEvent(searchQuery: string, numberOfProducts: number): void {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    const existingSearchEventIndex = dataLayer.findIndex((event) => event.event === 'search');

    if (existingSearchEventIndex !== -1) {
      dataLayer[existingSearchEventIndex].search_term = searchQuery;
      dataLayer[existingSearchEventIndex].number_of_results_shown = numberOfProducts;
      dataLayer[existingSearchEventIndex].timestamp = Date.now();
    } else {
      dataLayer.push({
        event: 'search',
        search_term: searchQuery,
        number_of_results_shown: numberOfProducts,
        timestamp: Date.now(),
        _clear: true,
      });
    }
  }

  resetDataLayer() {
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }

    if (dataLayer !== undefined && TagManager !== undefined) {
      dataLayer.length = 0;
      const gtmContainerReg = /GTM-/i;

      for (const gtmKey of Object.keys(TagManager)) {
        if (gtmContainerReg.test(gtmKey) && TagManager[gtmKey].dataLayer && TagManager[gtmKey].dataLayer.reset) {
          TagManager[gtmKey].dataLayer.reset();
        }
      }
    }
  }

  transactionRecord(data: any) {
    this.setUserId();
    const eventData = {
      ecommerce: {
        transaction_id: data.orderNumber,
        value: CurrencyHelpers.formatMoneyFloatValue(data.sum),
        tax: CurrencyHelpers.formatMoneyFloatValue(data.taxed?.totalTax),
        shipping: CurrencyHelpers.formatMoneyFloatValue(data.shippingInfo?.price),
        shipping_tier: data?.shippingMethodName,
        shipping_locale: data.shippingAddress.country,
        currency: data.sum.currencyCode,
        coupon: data.discountCodes && data.discountCodes.length > 0 ? data.discountCodes[0] : '',
        items: data.lineItems.map((lineItem: LineItem, index: number) => mapLineItemForDataLayer(lineItem, index)),
      },
      transaction: {
        subTotal: CurrencyHelpers.formatMoneyFloatValue(
          CurrencyHelpers.subtractCurrency(data?.sum, data.shippingInfo?.price),
        ),
        total_discount: calculateTotalDiscount(data.lineItems, data.sum.currencyCode),
        payment_method: data?.payments,
        no_of_products: data.lineItems?.length,
        quantity: data.lineItems.reduce((sum: number, lineItem: LineItem) => {
          return sum + lineItem.count;
        }, 0),
      },
    };
    window.localStorage.setItem('purchase_data', JSON.stringify(eventData));
  }

  transactionFinish(orderId: any) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    this.resetDataLayer();
    const storedData = window.localStorage.getItem('purchase_data');
    if (!storedData) {
      return;
    }
    const eventData = JSON.parse(storedData);
    window.localStorage.removeItem('purchase_data');
    if (eventData.ecommerce.transaction_id === undefined) {
      eventData.ecommerce.transaction_id = orderId;
    }
    eventData.event = 'purchase';
    eventData.timestamp = Date.now();
    eventData._clear = true;
    dataLayer.push(eventData);
  }

  removeFromBag(data: LineItem) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    this.resetDataLayer();
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'remove_from_cart',
      timestamp: Date.now(),
      ecommerce: {
        currency: data.price.currencyCode,
        value: CurrencyHelpers.formatMoneyFloatValue(data.price),
        items: [mapLineItemForDataLayer(data, 0)],
      },
      _clear: true,
    });
  }

  addPaymentInfo(cart: Cart, paymentMethod: string) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_payment_info',
      timestamp: Date.now(),
      ecommerce: {
        shipping_tier: cart.shippingInfo?.name,
        shipping_locale: undefined,
        shipping: CurrencyHelpers.formatMoneyFloatValue(cart.shippingInfo?.price),
        value: CurrencyHelpers.formatMoneyFloatValue(cart.sum),
        tax: CurrencyHelpers.formatMoneyFloatValue(cart.taxed?.totalTax),
        currency: cart.sum?.currencyCode,
        coupon: cart.discountCodes && cart.discountCodes?.length > 0 ? cart.discountCodes?.[0] : '',
        payment_type: paymentMethod,
        items: cart.lineItems?.map((lineItem: LineItem, index: number) => mapLineItemForDataLayer(lineItem, index)),
      },
      _clear: true,
    });
  }

  addShippingInfo(cart: Cart) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_shipping_info',
      timestamp: Date.now(),
      ecommerce: {
        shipping_tier: cart.shippingInfo?.name,
        shipping_locale: undefined,
        value: CurrencyHelpers.formatMoneyFloatValue(cart.sum),
        currency: cart.sum?.currencyCode,
        coupon: cart.discountCodes && cart.discountCodes?.length > 0 ? cart.discountCodes?.[0] : '',
        items: cart.lineItems?.map((lineItem: LineItem, index: number) => mapLineItemForDataLayer(lineItem, index)),
      },
      _clear: true,
    });
  }

  addToCart(lineItems: LineItem[], variantSku: string, quantity: number) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    const index = lineItems.findIndex((li) => li.variant?.sku === variantSku);
    if (index === -1) return;
    const lineItem = lineItems[index];
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_to_cart',
      timestamp: Date.now(),
      ecommerce: {
        currency: lineItem?.variant?.price?.currencyCode,
        value: CurrencyHelpers.formatMoneyFloatValue(
          CurrencyHelpers.multiplyCurrency(lineItem?.variant?.price, quantity),
        ),
        items: [mapLineItemForDataLayer(lineItem, index)],
      },
      _clear: true,
    });
  }

  viewItemList(items: AlgoliaProduct[], category: string, page = 1, itemsPerPage?: number) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    let itemsToPush: AlgoliaProduct[] = items;
    let startingIndex = 0;
    if (itemsPerPage) {
      if (items.length <= itemsPerPage) {
        itemsToPush = items;
      } else {
        itemsToPush = items.slice(items.length - itemsPerPage);
        startingIndex = items.length - itemsPerPage;
      }
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'view_item_list',
      timestamp: Date.now(),
      ecommerce: {
        item_list_id: undefined,
        item_list_name: category,
        page: page,
        items: itemsToPush.map((item: AlgoliaProduct, index) => ({
          index: startingIndex + index,
          affiliation: 'Thomas Pink',
          item_id: item.productId,
          item_product_id: undefined,
          item_parent_sku: undefined,
          item_name: item.name,
          item_brand: 'Thomas Pink',
          item_category: undefined,
          price: CurrencyHelpers.formatMoneyFloatValue({
            fractionDigits: 2,
            centAmount: item.price[1].currentPrice,
            currencyCode: item.price[0],
          }),
          item_original_price: CurrencyHelpers.formatMoneyFloatValue({
            fractionDigits: 2,
            centAmount: item.price[1].price,
            currencyCode: item.price[0],
          }),
          discount: item.price[1].discountedAmount
            ? CurrencyHelpers.formatMoneyFloatValue({
                fractionDigits: 2,
                centAmount: item.price[1].discountedAmount,
                currencyCode: item.price[0],
              })
            : 0,
          item_on_sale: !!item.price[1].discountedAmount,
          quantity: 1,
          item_fit: undefined,
          item_colour: undefined,
          item_cuff: undefined,
          item_length: undefined,
          item_size: undefined,
        })),
      },
    });
  }

  getDataLayer() {
    if (!window || !window[this.dataLayerIdentifier]) {
      return null;
    }

    return window[this.dataLayerIdentifier];
  }

  viewCart(cartType: 'Mini Cart' | 'Cart', cart: Cart) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'view_cart',
      view_cart_type: cartType,
      timestamp: Date.now(),
      ecommerce: {
        currency: cart.sum.currencyCode,
        value: CurrencyHelpers.formatMoneyFloatValue(cart.sum),
        coupon: cart.discountCodes && cart.discountCodes.length > 0 ? cart.discountCodes[0] : '',
        items: cart.lineItems.map((lineItem, index) => mapLineItemForDataLayer(lineItem, index)),
      },
      _clear: true,
    });
  }

  viewItem(product: Product, variant: ProductVariantOption) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }

    try {
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: 'view_item',
        timestamp: Date.now(),
        ecommerce: {
          currency: variant.price.currencyCode,
          value: variant.price.currencyCode,
          items: [
            {
              index: 0,
              affiliation: 'Thomas Pink',
              item_id: variant.sku,
              item_product_id: product.productId,
              item_parent_sku: product.variants[0]?.attributes['TP_ARTICLE_CODE'],
              item_name: product.name,
              item_brand: 'Thomas Pink',
              item_category: product.variants[0]?.attributes['TP_PRODUCT_TYPE'],
              price: CurrencyHelpers.formatMoneyFloatValue(variant.discountedPrice ?? variant.price),
              item_original_price: CurrencyHelpers.formatMoneyFloatValue(variant.price),
              discount: variant.discountedPrice
                ? CurrencyHelpers.formatMoneyFloatValue(
                    CurrencyHelpers.subtractCurrency(variant.price, variant.discountedPrice),
                  )
                : 0,
              item_on_sale: variant.discountedPrice !== undefined,
              quantity: variant.availableQuantity,
              item_fit: variant.fit,
              item_colour: variant.colour,
              item_cuff: variant.cuff,
              item_length: variant.sleeve,
              item_size: variant.size,
            },
          ],
        },
        _clear: true,
      });
    } catch (e) {
      console.error('viewItem', e);
    }
  }

  addBillingInfo(cart: Cart) {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    this.resetDataLayer();
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'add_billing_info',
      timestamp: Date.now(),
      ecommerce: {
        value: CurrencyHelpers.formatMoneyFloatValue(cart.sum),
        tax: CurrencyHelpers.formatMoneyFloatValue(cart.taxed?.totalTax),
        shipping: CurrencyHelpers.formatMoneyFloatValue(cart.shippingInfo?.price),
        shipping_tier: cart.shippingInfo?.name,
        shipping_locale: undefined,
        currency: cart.sum?.currencyCode,
        coupon: cart.discountCodes && cart.discountCodes?.length > 0 ? cart.discountCodes?.[0] : '',
        items: cart.lineItems?.map((lineItem: LineItem, index: number) => mapLineItemForDataLayer(lineItem, index)),
      },
      _clear: true,
    });
  }

  selectItem(product: AlgoliaProduct, listInfo: string, position: number): void {
    this.setUserId();
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'select_item',
      timestamp: Date.now(),
      ecommerce: {
        item_list_id: undefined,
        item_list_name: listInfo,
        items: [
          {
            index: 0,
            affiliation: 'Thomas Pink',
            item_id: product.productId,
            item_product_id: undefined,
            item_parent_sku: undefined,
            item_name: product.name,
            item_brand: 'Thomas Pink',
            item_category: undefined,
            price: CurrencyHelpers.formatMoneyFloatValue({
              fractionDigits: 2,
              centAmount: product.price[1]?.currentPrice,
              currencyCode: product.price[0],
            }),
            item_original_price: CurrencyHelpers.formatMoneyFloatValue({
              fractionDigits: 2,
              centAmount: product.price[1]?.price,
              currencyCode: product.price[0],
            }),
            discount: product.price[1].discountedAmount
              ? CurrencyHelpers.formatMoneyFloatValue({
                  fractionDigits: 2,
                  centAmount: product.price[1]?.discountedAmount,
                  currencyCode: product.price[0],
                })
              : 0,
            item_on_sale: !!product.price[1]?.discountedAmount,
            quantity: 1,
            item_fit: undefined,
            item_colour: undefined,
            item_cuff: undefined,
            item_length: undefined,
            item_size: undefined,
            position,
          },
        ],
      },
    });
  }

  viewPromotion(promotionId, promotionName) {
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'view_promotion',
      ecommerce: {
        creative_name: 'Newsletter Overlay',
        creative_slot: 'newsletter_overlay',
        promotion_id: promotionId,
        promotion_name: promotionName,
      },
    });
  }

  closeNewsletter() {
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ event: 'newsletter_overlay_close' });
  }

  signUpToNewsletter() {
    const dataLayer = this.getDataLayer();
    if (!dataLayer) {
      return;
    }
    dataLayer.push({ event: 'sign_up', method: 'Newsletter Overlay' });
  }
}

export default TagManager;
