export class StringHelpers {
  /**
   * isNumeric tests a string and returns true when it's a positive integer or zero
   */
  static isNumeric = (val: string) => /^\d+$/.test(val);

  /**
   * capitaliseFirstLetter capitalises only the very first character of a string, leaving the
   * rest unedited
   */
  static capitaliseFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

  static isEmailValid = (val: string) => {
    const regex = new RegExp(
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    );
    return regex.test(val);
  };

  /**
   * Checks for an empty string
   * @param val
   */
  static isEmpty = (val: string | undefined | null) => {
    if (!val) {
      return true;
    }

    return val.trim().length === 0;
  };
}
